import { LogoItem, Logoitem } from "../components/common/LogoItem"

const companies = [
  {
      name: "American Meetings (AMI)",
      image: "AMI.png"
  },
  {
      name: "Lideranca Group",
      image: "lg_main-logo4.png"
  },
  {
      name: "Bards of Nevermore",
      image: "bardsofnevermorelogo2.png"
  },
  {
      name: "Proximo",
      image: "ProximoLogo.png"
  },
  {
      name: "Moey",
      image: "moey-logo.png"
  },
  {
      name: "Columbia Consulting",
      image: "columbiaconsulting.png"
  },
  {
      name: "OBOX Solutions",
      image: "OBOXLOGO.png"
  },
  {
      name: "CyberGuard",
      image: "Cyber-Guardlogo.png"
  },
  {
      name: "Havens & Company",
      image: "Havens_Co.png"
  },
  {
      name: "MediaCopy",
      image: "MediaCopyLogo.png"
  },
  {
      name: "Fun Travel Guides",
      image: "FunTravelGuides.png"
  },
  {
      name: "WOSH",
      image: "wosh.png"
  },
  {
      name: "Kazaisu",
      image: "kaisazu.jpeg"
  },
  {
      name: "Citrix",
      image: "citrix.png"
  },
  {
      name: "Dunham Insurance Services",
      image: "DunhamIns.png"
  },
  {
      name: "Empowering Differences",
      image: "empoweringDifferences.png"
  },
  {
      name: "Starrit Consulting",
      image: "starrit.png"
  },
  {
      name: "Tom Bercu Presents",
      image: "tomBercu.png"
  },
  {
      name: "Windows Management Experts",
      image: "windowsManagement.png"
  },
  {
      name: "Grossman Group ",
      image: "grossmanlogo.jpg"
  }
];

// companies.sort((a, b) => {
//   if (a.name.toLowerCase() < b.name.toLowerCase()) {
//       return -1;
//   }
//   if (a.name.toLowerCase() > b.name.toLowerCase()) {
//       return 1;
//   }
//   return 0;
// });


export const Logos = () => {
    return (
      <div id="clients" className="bg-gray-900 py-24 w-full sm:py-32 bg-[linear-gradient(0deg,#5e7487,#0a0a0a,white)]">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white">
            Our Clients
            </h2>
            <p className="mt-6 text-lg leading-8 text-white drop-shadow-md">
            Diversitas has had the distinct honor of working with these amazing clients over the past 5 years.
            </p>
            <div className="mt-8 flex items-center gap-x-6">
              <a
                href="#clients"
                className="rounded-md bg-slate-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
              >
                Reach out to join their ranks
              </a>
              <a href="mailto:irwin@mydiversitas.com@yoursite.com?&subject=Website inquiry&body=I'm interested in your services" className="text-sm font-semibold text-white">
                Contact us <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="max-h-[450px] p-3 sm:p-5 bg-[radial-gradient(#30475a75_0_50%,transparent)] border border-white rounded-lg shadow-[0px_1px_20px_0px_black] overflow-y-auto mx-auto grid w-full max-w-xl grid-cols-1 sm:grid-cols-2 items-center gap-y-3 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
           {companies.map((co, index)=>(
               <LogoItem
               key={co.name+"-"+index}
               className="max-h-12 w-full object-contain object-left"
               image={co.image}
               name={co.name}
             />)
           )}

          </div>
        </div>
      </div>
    </div>
    )
  }
  