import { Logos } from '../../../sections/Clients';
import { Services } from '../../../sections/Services';
import { SpotLight } from '../../../sections/SpotLight';
import { TheTeam } from '../../../sections/TheTeam';
import { Footer } from '../../template/Footer';
import { Header } from '../../template/Header';
import './home.css';

export const Home = () => {
    return (
        
        <div className={"flex flex-col min-h-screen items-center justify-center dark:bg-gray-800 dark:text-white "} >
            <Header/>
            <SpotLight/>
            <TheTeam/>
            <Services/>
            <img src="/images/divider1.png" width="100%" className="bg-[linear-gradient(180deg,#4a4a4a_0_50%,white,transparent)]"/>
            <Logos/>
            <Footer/>
            
        </div>
    )
}