import React from 'react'
import bkg  from '../assets/images/heroBkg.png'
const posts = [
    {
        id: 1,
        title: 'Small Businesses',
        href: '#',
        description:
          'Offering critical assistance that will enable you to jump-start your journey down the road to success',
        date: 'Mar 16, 2020',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white " className="w-24 h-24 text-slate-800">
            <path fillRule="evenodd" d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z" clipRule="evenodd" />
        </svg>,      
         items:[
          'Introductions to corporate supply chain decision makers',
          'Creation of effective capabilities presentations',
          'Diverse-Owned Business certification assistance' 
        ]
      },
      {
        id: 1,
        title: 'Established Diverse-Owned Businesses',
        href: '#',
        description:
          'If you are primed for growth and ready to take things to the next level, you will benefit tremendously will benefit tremendously from:',
        date: 'Mar 16, 2020',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-24 h-24 text-slate-800">
        <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
        <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clipRule="evenodd" />
      </svg>
      ,
      items:[
        "Identifying “best-in-class” differentiators ",
        "Customizing targeted potential new business, by industry vertical",
        "Establishing/Refining your corporate culture",
        "Ensuring all key aspects of CSR and ESG are reflected in your strategy and corporate values",
        "Maximizing Profitability through effective Procurement and Supplier Diversity strategies.",
      ]
      },
      {
        id: 1,
        title: 'Large Corporations',
        href: '#',
        description:
          'Providing critical guidance to large corporations that need assistance in making their DEI-related programs world-class:',
        date: 'Mar 16, 2020',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-24 h-24 text-slate-800">
        <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
        <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clipRule="evenodd" />
        <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
      </svg>
      ,
      items:[
          "Globalizing your Supplier Diversity program",
          "Creating ERGs/BRGs that are meaningful",
          "Corporate Culture - “Walking the Walk”",
          "Attracting/Retaining Top Diverse Talent",
          "Designing DEI events that have impact",
          "Identifying hard-to-find diverse suppliers that have already been vetted",
          "Offering DEI education that provides executives and managers with the skills they need to become DEI champions"
      ]
    },

  ]
export const SpotLight = ()=>{
    return (
      <div id="spotlight" className="hero flex flex-col justify-center items-center p-2 sm:p-2 md:p-5 w-full bg-cover bg-center bg-no-repeat bg-[linear-gradient(180deg,black,#4a4a4a)]" >  {/* style={{backgroundImage:`url(${bkg})`}}> */}
        <div className=" xlg:max-w-[1072px] lg:max-w-[1072px] ">
        <div className="mx-auto flex flex-col justify-center items-center  text-center max-w-7xl px-6 pt-12">
        <h1 className="text-3xl text-[#4edf1b]">The "Diversitas Difference"</h1>
        <p  className="text-xl w-3/4  text-slate-300">Unequaled subject matter expertise and thought leadership, coupled with both a tremendous passion for Diversity, Equity & Inclusion, and a relentless commitment to helping others achieve their their maximum potential ... that's The Diversitas Difference.</p>
         
        </div>
        <div className="mx-8 mt-3 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post, index) => (
            <article key={index} className="flex max-w-xl flex-col items-start rounded-lg shadow-lg justify-start bg-[#ffffff80] p-10">
              <div className=""></div>
               <div className="flex flex-col justify-center items-center relative rounded-md ">
                {post.icon}

                <h2 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                 <span>{post.title}</span>
                </h2>
                <p className="mt-5 line-clamp-6 font-medium leading-6 text-slate-800">{post.description}</p>
                <ul className="text-sm list-disc  text-slate-700 ">
                  {post.items.map((item)=>(
                    <li>{item}</li>
                  ))}
                </ul>

              </div>
             
            </article>
          ))}
        </div>
      </div>
      </div>
    )
  }