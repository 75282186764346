import React from "react";

export const Services = () => {
  return (
    <div id="services" className="bg-[#4a4a4a]  w-[100vw] flex flex-row items-center justify-center">
      <div className="m-5 xlg:max-w-[1072px] lg:max-w-[1072px] sm:max-w-[90vw] flex flex-col items-center space-y-4 bg-[#4a4a4a]">
        <h1 className="text-white text-3xl "> Our Services</h1>
        
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex-1 border p-4">
            <div>
              <div className="flex flex-row justify-center">
                <svg
                  style={{ width: "150px", fill: "white" }}
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 24 24"
                >
                  <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div>
                <h2 className="text-2xl text-center mb-2 text-white">Business Development</h2>
                <div>
                  <p>
                    <ul className="list-disc m-4 text-slate-300">
                    <li>Creating an effective slide deck to use with potential clients that clearly identifies your value proposition</li>
                    <li>Leveraging our network of over 2500 contacts at over 1200 corporations to get you in front of key decision makers within the industry groupings that offer you the greatest potential for success</li>
                    <li>Providing customized lists of pre-vetted certified diverse suppliers for inclusion in your RFPs that are capable of providing the goods and services you need at the highest level of quality possible</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 border p-4">
            <div>
              <div className="flex flex-row justify-center">
                <svg
                  style={{ width: "150px", fill: "white" }}
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 24 24"
                  className="scale-75">
                  <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div>
                <h2 className="text-2xl text-center mb-2 text-white">Procurement Strategy</h2>
                <div>
                  <p>
                    <ul className="list-disc m-4 text-slate-300">
                      <li>
                        Reviewing your procurement strategy to ensure you are
                        removing as much cost and risk as possible from your
                        supply chain
                      </li>
                      <li>
                        Ensuring that your supplier diversity program is
                        world-class and implemented in every region where you do
                        business
                      </li>
                      <li>
                        Getting your company registered in the supplier
                        diversity portals of the hundreds of corporate partners
                        that require this as the first step to participating in
                        their global supply chain
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 border p-4">
            <div>
              <div>
                <div className="flex flex-row justify-center">
                  <svg
                    style={{ width: "150px", fill: "white" }}
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    viewBox="0 0 24 24"
                    className="scale-75">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
                  </svg>
                </div>
                <div>
                  <h2 className="text-2xl text-center mb-2 text-white">Diversity, Equity & Inclusion</h2>
                  <div>
                    <p>
                      <ul className="list-disc m-4 text-slate-300">
                        <li>
                          Creating inclusive and welcoming onboarding processes
                        </li>
                        <li>
                          Attracting and Retaining Top Diverse Talent by
                          implementing specific programs, including Recruiting
                          Practices, and Compensation and Promotion Equity
                          Analyses
                        </li>
                        <li>
                          Establishing or Refining ERGs/BRGs to ensure they have
                          meaningful purposes
                        </li>
                        <li>
                          Planning Diversity Events that have impact across your
                          entire organization
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center space-x-4">
          <div className="flex border p-4">
            <div>
              <div>
                <div className="flex flex-row justify-center">
                  <svg
                    style={{ width: "150px", fill: "white" }}
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    viewBox="0 0 24 24"
                    className="scale-75">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"></path>
                  </svg>
                </div>
                <div>
                  <h2 className="text-2xl text-center mb-2 text-white">Corporate Culture</h2>
                  <div>
                    <p>
                      <ul className="list-disc m-4 text-slate-300">
                        <li>
                          Ensuring your company has a Mission and Vision that
                          matches your value proposition
                        </li>
                        <li>
                          Creating or Refining your company’s publicly stated
                          values, and ensuring that they resonate with your
                          workforce
                        </li>
                        <li>
                          Evaluating every element of your corporate culture,
                          and making recommendations for change, where needed,
                          to ensure maximum success
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex border p-4">
            <div>
              <div>
                <div className="flex flex-row justify-center">
                  <svg
                    style={{ width: "150px", fill: "white" }}
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    viewBox="0 0 24 24"
                  className="scale-75">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                  </svg>
                </div>
                <div>
                  <h2 className="text-2xl text-center mb-2 text-white">Corporate Social Responsibility / ESG</h2>
                  <div>
                    <p>
                      <ul className="list-disc m-4 text-slate-300">
                        <li>
                          Reviewing your corporate sponsorships and making
                          recommendations to change, if necessary
                        </li>
                        <li>
                          Helping you understand the impact of your DEI programs
                          within the communities in which you do business
                        </li>
                        <li>
                          Evaluating the effectiveness of your corporate
                          philanthropy
                        </li>
                        <li>
                          Ensuring that your employee handbooks are up to date
                          and that you are in compliance with all applicable
                          laws and regulations
                        </li>
                        <li>
                          Ensuring that your company is implementing
                          sustainability programs whenever possible
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
