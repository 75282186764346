import React from 'react'
import bkg  from '../assets/images/heroBkg.png'
export const Hero = ()=>{
    return (
      <div id="cta" className="hero bg-white w-full bg-cover bg-center bg-no-repeat" style={{backgroundImage:`url(${bkg})`}}>
        <div className="mx-auto flex flex-col justify-center items-center  text-center max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <img src="/images/logo.png" className="w-1/2 md:w-2/3 sm:w-1/2" />
          <div className="text-lg font-bold rounded-xl bg-[#ffffff50] p-4 tracking-tight text-slate-600 sm:text-4xl">
            A leader in diverse business strategies
            <div className="mt-10 flex items-center justify-center gap-x-6">
            
            <a
              href="#spotlight"
              className="rounded-md bg-slate-500 hover:bg-slate-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
              Learn More
            </a>
          </div>
          </div>
         
        </div>
      </div>
    )
  }