import React from "react";
import bkg from "../assets/images/bkg1.png";
import bkg2 from "../assets/images/card-bkg3.png";

export const TheTeam = () => {
  return (
    <div
      id="the-team"
      style={{ backgroundImage: `url(${bkg})` }}
      className="min-h-[400px] flex flex-col justify-between items-center content-between"
    >
      <img src="/images/divider2.png" className="w-full" alt="Divider" />

      <div className="max-w-[1072px]">
        <div className="p-5" >
        <h1 className="text-3xl">Who We Are</h1>
        <blockquotes>
        <p className="text-md">
          Diversitas, an NGLCC-certified LGBT-owned business, is a consulting
          practice that specializes in assisting certified diverse-owned
          businesses (MBE, WBE, LGBTBE, DOBE, VOBE, etc), as well as large
          corporations that have programs in place to assist them grow, with DEI
          programs, business development, procurement strategies, supplier
          diversity, compliance, and various other aspects of Corporate Social
          Responsibility (CSR) and Environmental, Social and Governance (ESG)
          programs.
        </p>
        </blockquotes>


        </div>
        <div className="flex flex-col justify-around p-4">
          <div
            className="team-card  max-w-[90vw] bg-[linear-gradient(45deg,#5061706b,#dadada)]  bg-cover min-h-[400px] flex flex-col sm:flex-row content-center justify-center items-center bg-bottom shadow-[2px_2px_20px_black] m-5 p-2.5 rounded-[20px]"
          >
            <div className=" w-full sm:w-1/4">
            <img src="/images/IRWIN.jpg" className="w-full max-w-[300px]" alt="Irwin Drucker" />
            <h1 className="mt-4 text-3xl">Irwin Drucker</h1>
            <h2 className="mb-4 text-xl text-slate-700">President</h2>
            </div>
            <div className="card-description w-3/4 ml-5 min-h-[400px] p-4 text-md text-slate-700 h-full bg-[linear-gradient(45deg,#cecece,#dadada)] m-5 rounded-lg border flex flex-col border-white max-h-[250px] overflow-auto">
              Diversitas was established by Irwin in July, 2018. Having served
              as the Program Director of IBM’s Global and LGBT Supplier
              Diversity organization for 15 years, as well as a Board Member of
              the National LGBT Chamber of Commerce and the Greater Fort
              Lauderdale LGBT Chamber of Commerce, and also a member of the
              International Committees at both the NMSDC and WBENC, Irwin has
              unmatched qualifications as a consultant to the diversity business
              community. A graduate of both New York University and Georgetown,
              Irwin spent almost thirty years working in Corporate America.
              <br />
              <div className="flex flex-col">
              <strong>After his departure from IBM, Irwin served as:</strong>
              <ul style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li>Executive Director of the PYP Foundation</li>
                <li>Chief Procurement & Compliance Officer at WOSH</li>
                <li>Chief People & Diversity Officer at Acronym</li>
              </ul>
              </div>
              <div>
              <strong>
                In recognition of his leadership, Irwin has received:
              </strong>
              <ul style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li>
                  NGLCC’s first Supplier Diversity Advocate of the Year Award
                </li>
                <li>
                  Florida Diversity Council’s Multicultural Leadership Award
                </li>
                <li>NGLCC’s first Legacy Award</li>
                <li>
                  Business Equality Magazine’s LGBT Legacy Leaders: 50 Over 50
                </li>
              </ul>
              </div>
              <div>
              <strong>
                Irwin has been featured in press articles and interviews in:
              </strong>
              <ul style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                <li>Business Equality Magazine</li>
                <li>Affinity Magazine</li>
                <li>Metro Weekly</li>
                <li>OMG</li>
                <li>Diversity 411</li>
                <li>Bloomberg Business News</li>
                <li>The Advocate</li>
                <li>Windy City Times</li>
              </ul>
              </div>
            </div>
          </div>

          <div
            className="team-card  max-w-[90vw] bg-[linear-gradient(45deg,#5061706b,#dadada)]  bg-cover min-h-[400px] flex flex-col sm:flex-row content-center justify-center items-center bg-bottom shadow-[2px_2px_20px_black] m-5 p-2.5 rounded-[20px]"
          >
            <div  className=" w-full sm:w-1/4">
            <img src="/images/DAVID.jpeg" className="w-full max-w-[300px]" alt="Irwin Drucker" />
            <h1 className="mt-4 text-3xl">David Randall</h1>
            <h2 className="mb-4 text-xl text-slate-700">Director Client Services</h2>
            </div>
            <p className="card-description ml-5 h-full p-4 min-h-[400px] w-3/4 text-md text-slate-700 bg-[linear-gradient(45deg,#cecece,#dadada)] m-5 rounded-lg border flex flex-col border-white max-h-[250px] overflow-auto">
                David joined the Diversitas Team as our Director of Client Services in November, 2019. In that role, he is responsible for fulfilling a variety of client-focused projects, including managing supplier diversity portal registrations, creating client capabilities presentations, designing and implementing client DEI and HR dashboard reports, designing internal client newsletters, and managing the data entry activity into client sales leads databases, as well as implementing Diversitas’ social media strategy.
            </p>
          </div>
        </div>
      </div>

      <img src="/images/divider2-a.png" className="w-full" alt="Divider" />
    </div>
  );
};
