
import React from "react"

export const LogoItem = ({image, name, rest})=>{
    return (
        <div {...rest} className="flex flex-row items-center justify-start min-h-[100px]">
            <img src={`/images/logos/${image}`} width="75" height="75" className="mr-3 rounded-full border-2 border-white bg-black max-h-[75px] maq-w-[75px] text-white"/>
            <h3 className=" text-white text-md">{name}</h3>
        </div>
    )
}